const API_URL = process.env.VUE_APP_API_ENDPOINT;

export function GetRecentCommentsList() {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  let access_group = payload.access_group;
  let dataRequest = {
    access_group: access_group,
    limit: 10
  };
  return fetch(API_URL + "/manifest/getLastCommentsLeads", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken
    },
    body: JSON.stringify(dataRequest)
  })
    .then(res => res.json())
    .then(json => json.Data);
}

<template>
  <v-container>
    <h1 class="text-center">
      Comentarios recientes
    </h1>
    <v-container fluid>
      <div v-if="loading">
        <v-row justify="center" align="center">
          <v-progress-circular :size="150" color="primary" indeterminate>
            Cargando...
          </v-progress-circular>
        </v-row>
      </div>
      <div v-else>
        <v-row justify="center">
          <!-- <v-subheader>Today</v-subheader> -->

          <v-expansion-panels popout>
            <v-expansion-panel
              v-for="item in recentComments"
              :key="item._id"
              hide-actions
            >
              <v-expansion-panel-header @click="getCommentbyId(item.lead_id)">
                <v-row align="center" class="spacer" no-gutters>
                  <v-col cols="4" sm="2" md="1">
                    <v-avatar size="36px">
                      <v-img
                        v-if="item.contact_media_featured_image_src === ''"
                        :src="
                          `https://dummyimage.com/600x400/2c344b/fff&text=` +
                            item.contact_name
                        "
                      ></v-img>
                      <v-img
                        v-else
                        :src="item.contact_media_featured_image_src"
                      ></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col class="text-no-wrap" cols="5" sm="3">
                    <span>
                      <p>{{ item.contact_name }}</p>
                    </span>
                  </v-col>

                  <v-col>
                    <div class="caption">
                      <strong>{{ item.lead_contact_lead_name }}</strong>
                    </div>
                  </v-col>
                  <v-col class="grey--text text-truncate hidden-sm-and-down">
                    &mdash; {{ item.comment_text }}
                  </v-col>
                  <v-col class="text-no-wrap" cols="5" sm="3">
                    <v-chip
                      color="green lighten-4"
                      class="ml-0 mr-2 black--text"
                      label
                      small
                    >
                      {{ item.comment_created_at | dateFormat(true) }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-card-text>
                  <!-- {{ allComments[findIndexbyValue(item.lead_id)].comments }} -->
                  <v-timeline align-top dense>
                    <div v-for="item in commentbyId" :key="item._id">
                      <v-timeline-item class="mb-6" color="blue accent-1" small>
                        <v-row class="pt-1">
                          <v-col cols="3">
                            <div class="caption">Fecha</div>
                            <strong>
                              {{ item.created_at | dateFormat(true) }}</strong
                            >
                          </v-col>
                          <v-col>
                            <div class="caption">Comentario</div>
                            <strong>
                              {{ item.text }}
                            </strong>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </div>
                  </v-timeline>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>
<script>
import { GetRecentCommentsList } from "@/api/manifest-log-api.js";
import moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    commentbyId: [],
    recentComments: [],
    allComments: [],
    dataComment: [],
    loading: true,
    message: ""
  }),
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "LLLL";
      if (isFullDate) format = "LL h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    }
  },
  computed: {
    showAllComments() {
      return this.allComments;
    }
  },
  created() {
    this.consultManifestLogList();
  },
  methods: {
    findIndexbyValue(id) {
      return this.allComments.findIndex(e => {
        return e._id === id;
      });
    },
    getCommentbyId(id) {
      let index = this.allComments.findIndex(e => {
        return e._id === id;
      });

      this.commentbyId = this.allComments[index].comments.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },
    consultManifestLogList() {
      let self = this;
      setTimeout(() => {
        GetRecentCommentsList()
          .then(res => {
            // self.recentComments = res.Manifest;
            self.allComments = res.Manifest;
            self.recentComments = res.sortedComments;
          })
          .finally(() => (self.loading = false));
      }, 1500);
    }
  }
};
</script>
